import { Routes, Route, Link} from 'react-router-dom';
import twitter from './assets/images/twitter.svg';
import telegram from './assets/images/telegram.svg';
import './App.css';
import Home from './compoments/Home'
import Staking from './compoments/staking'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='App-name'>
          <h1><a href='/'>BTC <span>Halving</span></a></h1>
        </div>
        <div className='App-wrapper-nav'>
        <div className='App-nav'>
          <ul>
            {/* <li><Link to='/#About'>About</Link></li> */}
            <li><Link to='/staking'>Staking</Link></li>
            <li><a href='https://contractwolf.io/projects/bitcoin-halving-token' target='_blank'>Audit</a></li>
            <li><a href='https://bitcoin-halving-token.gitbook.io/bitcoin-halving-token' target='_blank'>Whitepaper</a></li>
          </ul>
        </div>
        <div className='App-join'>
          <ul>
            <li><a href='https://twitter.com/BTChalvingtoken' target='_blank'><img src={twitter}/></a></li>
            <li><a href='https://t.me/BitcoinHalving_Official' target='_blank'><img src={telegram}/></a></li>
            {/* <li><a><img/></a></li> */}
          </ul>
        </div>
        {/* <div className='App-buy'>
          <button><Link to='/staking'>Staking</Link></button>
        </div> */}
        </div>
      </header>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/staking' element={<Staking/>}/>
      </Routes>
      <footer>
        <div className='info-f'>
          <a href='https://twitter.com/BTChalvingtoken' target='_blank'><img src={twitter}/></a>
          <a href='https://t.me/BitcoinHalving_Official' target='_blank'><img src={telegram}/></a>
        </div>
        <div className='copyright'>@2022 BTC HALVING TOKEN</div>
      </footer>
    </div>
  );
}

export default App;
