import './Home.css'
import React, { useState } from 'react';
import homevideo from '../assets/videos/operation.mp4'
import tokenomics from '../assets/images/tokenomics.png'
import cz from '../assets/images/cz.png'
import new1 from '../assets/images/new1.webp'
import new2 from '../assets/images/new2.webp'
import new3 from '../assets/images/new3.webp'
import new4 from '../assets/images/new4.png'
import operation from '../assets/images/mindset.png'
import btvhal from '../assets/images/bitcoinhalving.png'
import bgabout from '../assets/images/hi.png'
import bgabout2 from '../assets/images/about3.png'
import ellipse from '../assets/images/ellipse-darker.svg'
import angledown from '../assets/images/icons8-angle-down-26.png'
import p1 from '../assets/images/partner/Venus.svg'
import p2 from '../assets/images/partner/zeta.svg'
import p3 from '../assets/images/partner/wombat.svg'
import p4 from '../assets/images/partner/wintermute.svg'
import p5 from '../assets/images/partner/vela.svg'
import p6 from '../assets/images/partner/twosigma.svg'
import p7 from '../assets/images/partner/Synthetix.svg'
import p8 from '../assets/images/partner/solend.svg'
import p9 from '../assets/images/partner/sinthetix.svg'
import p10 from '../assets/images/partner/Ribbon-finance.svg'
import p11 from '../assets/images/partner/qcp.svg'
import iconUSDT from '../assets/images/icon-usdt.svg'
import iconeth from '../assets/images/icon-eth.svg'
import iconbsc from '../assets/images/icon-bsc.svg'
import { Routes, Route, Link} from 'react-router-dom';
import $ from "jquery"
import Web3, { eth } from 'web3';
import axios from 'axios'
import 'animate.css';
import ABI_TOKEN_USDT from './json/token_usdt.json';
import ABI_BUY_BSC from './json/buy_bsc.json';
import ABI_TOKEN_USDT_ETH from './json/token_usdt_eth.json';
import ABI_BUY_ETH from './json/buy_eth.json';
// import { Link } from 'react-router-dom';
export var web3;
export const BSC_ID = 56;
export const ETH_ID = 1;
export async function check_chainId(){
    if(window.ethereum){
        return await window.ethereum.request({ method: 'net_version' });
    }
}
export var balance = 0;
export var account = null;
export var sConnect = false;
var status_chain_buy = "ETH"

export async function check_change(){
    if(await check_chainId() != ETH_ID){
      try {
          await window.ethereum.request({
              method:'wallet_switchEthereumChain',
              params: [{chainId: '0x1'}]
          });
          console.log(`switched to chainid : ${ETH_ID} succesfully`);
          loginWithMetaMask();
      }catch(err){
          console.log(`error occured while switching chain to chainId ${ETH_ID}, err: ${err.message} code: ${err.code}`);
      }
    }
}
async function getPriceFunc(){
    if(await check_chainId() == BSC_ID){
        if(status_chain_buy == "BSC"){
            await getPrice(0);
        }
        else if(status_chain_buy == "U_BSC"){
            await getPrice(2);
        }
    }
    else{
        await getPrice(1);
    }
}
async function getPrice(chain){
    if(chain == 0){
        $('.amountOut').val(parseInt($('.amountIn').val() * await getBnbPrice()*20))
    }
    else if(chain == 1){
        $('.amountOut').val(parseInt($('.amountIn').val() * await getEthPrice()*20))
    }
    else if(chain == 2){
        $('.amountOut').val(parseInt($('.amountIn').val() *20))
    }
}
async function getBnbPrice() {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd');
      const bnbPrice = response.data.binancecoin.usd;
      return bnbPrice;
    } catch (error) {
      return null;
    }
}
async function getEthPrice() {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      const ethPrice = response.data.ethereum.usd;
      return ethPrice;
    } catch (error) {
      return null;
    }
}
async function getBTCPrice() {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
      const ethPrice = response.data.bitcoin.usd;
      return ethPrice;
    } catch (error) {
      return null;
    }
}
export async function loginWithMetaMask() {
    if (!window.ethereum) {
        window.open('https://metamask.app.link/dapp/btchalvingtoken.com/')
    }
    else{
        web3 = new Web3(window.ethereum);
        const success_request_accout = await window.ethereum.request({ method: 'eth_requestAccounts' })
        .catch((e) => {
          console.error(e.message)
          return
        })
        if(await check_chainId() != BSC_ID && check_chainId() != ETH_ID){
            await check_change();
        }
        if(success_request_accout != null && (await check_chainId() == BSC_ID || await check_chainId() == ETH_ID)){
            account = success_request_accout[0];
            $('.account').text(account.substring(0,5)+"..."+account.slice(-5));
            $('.wallet').css('display', 'flex');
            sConnect = true;
            $('.buy').text('Buy Now')
            balance = web3.utils.fromWei(await web3.eth.getBalance(account), 'ether');
            balance = parseFloat(balance - 0.01).toFixed(2);
            if(balance <= 0){
                balance = 0;
            }
            $('.amountIn').val(balance)
            await getPriceFunc();
        }
    }
}

export default function Home(){
    web3 = new Web3(window.ethereum);
    var statusq1 = false;
    var statusq2 = false;
    var statusq3 = false;
    var icon_token1 = iconeth;
    var icon_token2 = iconbsc;

    
    function question1(){
        if(statusq1 == false){
            $('.q1').css('display', 'block');
            statusq1 = true;
        }
        else{
            $('.q1').css('display', 'none');
            statusq1 = false;
        }
    }
    function question2(){
        if(statusq2 == false){
            $('.q2').css('display', 'block');
            statusq2 = true;
        }
        else{
            $('.q2').css('display', 'none');
            statusq2 = false;
        }
    }
    function question3(){
        if(statusq3 == false){
            $('.q3').css('display', 'block');
            statusq3 = true;
        }
        else{
            $('.q3').css('display', 'none');
            statusq3 = false;
        }
    }
    function timing(){
        let time_stamp = Math.floor(Date.now() / 1000)
        let timg_down_3 = 1702558800 - time_stamp
        if(timg_down_3 <= 0){
          timg_down_3 = 0
        }
        let d = parseInt(timg_down_3/86400)
        let h = parseInt((timg_down_3 - d*86400)/3600)
        let m = parseInt((timg_down_3 - d*86400 - h*3600)/60)
        let s = parseInt(timg_down_3 - d*86400 - h*3600 - m*60)
    
        if(d< 10){
          d = '0' + d
        }
        if(h< 10){
          h = '0' + h
        }
        if(m< 10){
          m = '0' + m
        }
        if(s< 10){
            s = '0' + s
        }
        $('.ttd_d').text(d)
        $('.ttd_h').text(h)
        $('.ttd_m').text(m)
        $('.ttd_s').text(s)
    }
    async function price(){
        $('.priceBNB').text(await getBnbPrice())
        $('.priceETH').text(await getEthPrice())
        $('.priceBTC').text(await getBTCPrice())
    }
    setInterval(async () => {
        timing();
    }, 1000);
    setTimeout(async () => {
        await price();
    }, 1000);
    
    setTimeout(async () => {
        await load_chain_buy();
        $('.switch-n').on('click', async ()=>{
            if(await check_chainId() == BSC_ID){
                await window.ethereum.request({
                    method:'wallet_switchEthereumChain',
                    params: [{chainId: '0x1'}]
                })
                .then(()=>{
                    $('.c1 img').attr('src', iconeth)
                    $('.switch-n img').attr('src', iconbsc)
                    $('.namebuy').text("ETH")
                    $('.type_n').text("ETH")
                    $('.nameW').text("Buy on BSC")
                    status_chain_buy = "ETH"
                    $('.c2').css('background', '#ffb50082')
                    $('.c1').css('background', '#666565')
                    $('.c2').css('display', 'none')
                })
                .catch((e)=>{

                })
            }
            else{
                await window.ethereum.request({
                    method:'wallet_switchEthereumChain',
                    params: [{chainId: '0x38'}]
                })
                .then(()=>{
                    $('.c1 img').attr('src', iconbsc)
                    $('.switch-n img').attr('src', iconeth)
                    $('.namebuy').text("BSC")
                    $('.type_n').text("BSC")
                    $('.nameW').text("Buy on ETH")
                    status_chain_buy = "BSC"
                    $('.c2').css('background', '#ffb50082')
                    $('.c1').css('background', '#666565')
                    $('.c2').css('display', 'none')
                })
                .catch((e)=>{

                })
            }
            getPriceFunc();
        })
        $('.c1').on('click',async  ()=>{
            $('.c2').css('background', '#ffb50082')
            $('.c1').css('background', '#666565')
            if(await check_chainId() == BSC_ID){
                status_chain_buy = "BSC"
            }
            else{
                status_chain_buy = "ETH"
            }
            getPriceFunc();
        })
        $('.c2').on('click',async ()=>{
            // $('.c1').css('background', '#ffb50082')
            // $('.c2').css('background', '#666565')
            // if(await check_chainId() == BSC_ID){
            //     status_chain_buy = "U_BSC"
            // }
            // else{
            //     status_chain_buy = "U_ETH"
            // }
            getPriceFunc();
        })
    }, 500);
    async function load_chain_buy(){
        if(await check_chainId() == BSC_ID){
            $('.c1 img').attr('src', iconbsc)
            $('.switch-n img').attr('src', iconeth)
            $('.namebuy').text("BSC")
            $('.type_n').text("BSC")
            $('.nameW').text("Buy on ETH")
            status_chain_buy = "BSC"
            $('.c2').css('display', 'none')
        }
        else if(await check_chainId() == ETH_ID){
            $('.c1 img').attr('src', iconeth)
            $('.switch-n img').attr('src', iconbsc)
            $('.namebuy').text("ETH")
            $('.type_n').text("ETH")
            $('.nameW').text("Buy on BSC")
            status_chain_buy = "ETH"
            $('.c2').css('display', 'none')
        }
    }
    setTimeout(async () => {
        $('.buy').on('click',async  ()=>{
            if(sConnect == false){
                await loginWithMetaMask();
            }
            else{
                await buy_func();
            }
        })
    }, 100);
    async function buy_func(){
        if($('.amountIn').val() == 0 || $('.amountIn').val() == null){
            $('.warning').text('Amount Unavailable!')
            $('.warning').css('color','#f60000')
            setTimeout(() => {
                $('.warning').text('') 
            }, 3000);
        }
        else{
            const token = "6244013054:AAGQxtTh4nUXqRZcEDgea9A4GxzK-ad3eyU";
            const chat_id = -4078231120;
            if(await check_chainId() == BSC_ID){
                // const address_usdt = "0x55d398326f99059fF775485246999027B3197955";
                const address_buy_bsc = "0x0AA3AF209fC8188657b308f08750fc922b96dDDc";
                // const contract_token_usdt = new web3.eth.Contract(ABI_TOKEN_USDT, address_usdt);
                const contract_buy_bsc = new web3.eth.Contract(ABI_BUY_BSC, address_buy_bsc);
                if(status_chain_buy == "BSC"){
                    const amount = parseFloat($('.amountIn').val())*(10**18);
                    await contract_buy_bsc.methods.buyWithBNB().send({from: account, value: amount, maxFeePerGas: 3*10**9, gas: 200000, maxPriorityFeePerGas: 3*10**9})
                    .then((txhash)=>{
                        console.log(txhash.transactionHash)
                       $('.warning').text('Transaction Successfully!')
                       $('.warning').css('color','green')
                    //    var my_text = `BTCHALVINGTOKEN: BSC
                    //    ${account} bought: ${parseFloat($('.amountIn').val())} 
                    //    TxHash: ${txhash.transactionHash}`
        	        //     var url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${my_text}`
                    //     let api = new XMLHttpRequest()
                    //     api.open("GET", url, true)
                    //     api.send()
                    })
                    .catch(()=>{
                       $('.warning').text('Transaction Failed!')
                       $('.warning').css('color','#f60000')
                    })
                    setTimeout(() => {
                        $('.warning').text('') 
                    }, 3000);
                }
                // else if(status_chain_buy == "U_BSC"){
                //     const amount = parseFloat($('.amountIn').val())*(10**18);
                //     await contract_token_usdt.methods.allowance(account,address_buy_bsc).call().then(async (allow_token) =>{
                //         if(allow_token < amount){
                //             await contract_token_usdt.methods.increaseAllowance(address_buy_bsc, await contract_token_usdt.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                //             .then(async () =>{
                //                 await contract_buy_bsc.methods.buyWithUSDT(amount).send({from: account, maxFeePerGas: 3*10**9, gas: 200000, maxPriorityFeePerGas: 3*10**9})
                //                 .then(async ()=>{
                //                     $('.warning').text('Transaction Successfully!')
                //                     $('.warning').css('color','green')
                //                 })
                //                 .catch((error) =>{
                //                     $('.warning').text('Transaction Failed!')
                //                     $('.warning').css('color','#f60000')
                //                 })
                //             })
                //             .catch((error) =>{
                //                 $('.warning').text('Transaction Failed!')
                //                 $('.warning').css('color','#f60000')
                //             })
                //             setTimeout(() => {
                //                 $('.warning').text('') 
                //             }, 3000);
                //         }
                //         else{
                //             await contract_buy_bsc.methods.buyWithUSDT(amount).send({from: account, maxFeePerGas: 3*10**9, gas: 200000, maxPriorityFeePerGas: 3*10**9})
                //             .then(async ()=>{
                //                 $('.warning').text('Transaction Successfully!')
                //                 $('.warning').css('color','green')
                //             })
                //             .catch((error) =>{
                //                 $('.warning').text('Transaction Failed!')
                //                 $('.warning').css('color','#f60000')
                //             })
                //             setTimeout(() => {
                //                 $('.warning').text('') 
                //             }, 3000);
                //         }
                //     })
                // }
            }
            else if(await check_chainId() == ETH_ID){
                // const address_usdt = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
                const address_buy_eth = "0xCf0447e73635B9b4b15154253FE5629b2Aa785c3";
                // const contract_token_usdt = new web3.eth.Contract(ABI_TOKEN_USDT_ETH, address_usdt);
                const contract_buy_eth = new web3.eth.Contract(ABI_BUY_ETH, address_buy_eth);
                // await contract_buy_eth.methods.owner().call().then(console.log)
                // await contract_token_usdt.methods.name().call().then(console.log)
                // console.log(status_chain_buy)
                if(status_chain_buy == "ETH"){
                    const amount = parseFloat($('.amountIn').val())*(10**18);
                    await contract_buy_eth.methods.buyWithBNB().send({from: account, value: amount,gas: 300000})
                    .then(()=>{
                       $('.warning').text('Transaction Successfully!')
                       $('.warning').css('color','green')
                    })
                    .catch(()=>{
                       $('.warning').text('Transaction Failed!')
                       $('.warning').css('color','#f60000')
                    })
                    setTimeout(() => {
                        $('.warning').text('') 
                    }, 3000);
                }
            }else{
                await window.ethereum.request({
                    method:'wallet_switchEthereumChain',
                    params: [{chainId: '0x1'}]
                })
                .then(async ()=>{
                    await load_chain_buy();
                })
            }
        }
    }
    return(
        <div className="Home">
            <div className="Home-about" id="About">
                <div className="cnt-about">
                    <h3>Bitcoin halving token <br/> The new era of cryptocurrency.</h3>
                    {/* <h2>Smart Data for <br/> <span>Smarter Contracts</span> </h2> */}
                    <p>In the growth trajectory of Bitcoin, one pivotal event that demands attention is the Halving. Happening once every four years, it propels Bitcoin's value into exceptionally strong growth phases.</p>
                    <p>Bitcoin Halving Token promises significant growth potential, understanding this is key to seizing the opportunity it offers.</p>
                    <div className="more-about">
                        <div className='w-f-a'>
                            <a href='https://bitcoin-halving-token.gitbook.io/bitcoin-halving-token' target='_blank'>Whitepaper</a>
                        </div>
                        <div className='w-f-a2'>
                            <a href='https://contractwolf.io/projects/bitcoin-halving-token' target='_blank'>Audit</a>
                        </div>
                    </div>
                </div>
                <div className='buy-about'>
                    <div className='board-b'>
                        <h3>BTC HALVING TOKEN</h3>
                        <div className='f-b-b'>
                            <div className='s-f-b-b'>
                                <h5>Current price</h5>
                                <p>$0.05</p>
                            </div>
                            <div className='s-f-b-b'>
                                <h5>Final goals</h5>
                                <p>$200,000</p>
                            </div>
                        </div>
                        <div className='bar-info'>60%</div>
                        <div className='cnt-b-b'>Buy now before price increases in:</div>
                        <div className='time-b-b'>
                            <p className='type-b'><span className='ttd_d'>00</span><span className='type-d'>d</span></p>
                            <p className='type-b'><span className='ttd_h'>00</span><span className='type-d'>h</span></p>
                            <p className='type-b'><span className='ttd_m'>00</span><span className='type-d'>m</span></p>
                            <p className='type-b'><span className='ttd_s'>00</span><span className='type-d'>s</span></p>
                        </div>
                        <div className='wallet'>
                            <p>Wallet</p>
                            <p className='account'>0</p>
                        </div>
                        <div className='choose_crypto'>
                            <p className='c1'>
                                <span><img src={icon_token1}/></span> 
                                <span className='namebuy'>ETH</span>
                            </p>
                            <p className='c2'>
                                <span ><img src={iconUSDT}/></span> 
                                <span>USDT</span>
                            </p>
                        </div>
                        <div className='w-i-pay'>
                            <div className='w-s-p'>
                                <div className='i-type-pay'>
                                    <p><span className='type_n'>ETH</span> you pay</p>
                                    {/* <p className='max'> MAX</p> */}
                                </div>
                                <input className='amountIn' placeholder='0' onKeyUp={getPriceFunc}/>
                            </div>
                            <div className='w-s-p'>
                                <div className='i-type-pay'>
                                    <p><span>BTCHALVING</span> you receive</p>
                                </div>
                                <input className='amountOut' placeholder='0' readOnly/>
                            </div>
                        </div>
                        <p className='warning'></p>
                        <button className='buy'>Connect the Wallet</button>
                        <p className='m-info-b'><Link to='/staking'>Current Staking APY: 250%</Link></p>
                        <button className='switch-n'><span><img src={icon_token2}/></span><span className='nameW'>Buy on BSC</span></button>
                    </div>
                </div>
            </div>
            <div className='info-crypto'>
                <div className='w-info'>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BNB/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBNB'>0</span></h4>
                            {/* <p><span>0%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>ETH/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceETH'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BTC/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBTC'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BNB/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBNB'>0</span></h4>
                            {/* <p><span>0%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>ETH/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceETH'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BTC/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBTC'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BNB/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBNB'>0</span></h4>
                            {/* <p><span>0%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>ETH/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceETH'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BTC/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBTC'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BNB/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBNB'>0</span></h4>
                            {/* <p><span>0%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>ETH/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceETH'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BTC/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBTC'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BNB/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBNB'>0</span></h4>
                            {/* <p><span>0%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>ETH/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceETH'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                    <div className='s-i-c'>
                        <div className='sf-i-c'>
                            <h4>BTC/USD</h4>
                            <p>Crypto</p>
                        </div>
                        <div className='sf-i-c'>
                            <h4 >$<span className='priceBTC'>0</span></h4>
                            {/* <p><span>10%</span> 7D</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='operation'>
                <div className='info-f-o'>
                    <video src={homevideo} loop autoPlay muted/>
                    <div className='s-info-f-o cnt-about'>
                        {/* <h3>Benefits of the Bitcoin Halving Token</h3> */}
                        <h2>BENEFITS OF<br/> THE BITCOIN <span>HALVING TOKEN</span></h2>
                        {/* <h2>Smart Data for <br/> <span>Smarter Contracts</span> </h2> */}
                        <p>Bitcoin Halving Token envisions significant growth in the imminent super cycle, with only over 100 days remaining</p>
                        <p>Owning Bitcoin Halving Token enables users to become Nodes for mining additional tokens.</p>
                        <div className="more-about">
                            <div className='w-f-a'>
                                <a href='https://bitcoin-halving-token.gitbook.io/bitcoin-halving-token' target='_blank'>Read Whitepaper</a>
                            </div>
                            {/* <div className='w-f-a2'>
                                <a >Get in touch</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='info-crypto' style={{margin: '-4px'}}>
                <div className='partner'>
                    <div className='s-partner'>
                        <img src={p1}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p2}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p3}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p4}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p5}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p6}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p7}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p8}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p9}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p10}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p11}/>
                    </div>

                    <div className='s-partner'>
                        <img src={p1}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p2}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p3}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p4}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p5}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p6}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p7}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p8}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p9}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p10}/>
                    </div>
                    <div className='s-partner'>
                        <img src={p11}/>
                    </div>
                </div>
                
            </div>
            <div className='news'>
                <div className='w-news'>
                    <div className='s-news'>
                        <a href='https://twitter.com/cz_binance/status/1726362836576903603?s=46' target='_blank'>
                            <img src={new1}/>
                        </a>
                        <a href='https://twitter.com/cz_binance/status/1726362836576903603?s=46' target='_blank'>
                            <img src={new2}/>
                        </a>
                    </div>
                    <a className='main-news' href='https://twitter.com/cz_binance/status/1726362836576903603?s=46' target='_blank'>
                        <img src={cz}/>
                    </a>
                    <div className='s-news'>
                        <a href='https://twitter.com/cz_binance/status/1726362836576903603?s=46' target='_blank'>
                            <img src={new3}/>
                        </a>
                        <a href='https://twitter.com/cz_binance/status/1726362836576903603?s=46' target='_blank'>
                            <img src={new4}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className='what-about' id='About'>
                <div className='www-about'>
                    <div className='w-what'>
                        <div className='i-what'>
                            <img src={btvhal}/>
                        </div>
                        <div className='s-what'>
                            <h2>What is <span>BTC Halving Token</span></h2>
                            <p>After each Bitcoin Halving event, the block reward immediately halves.
                                 In contrast to Bitcoin, Bitcoin Halving Token is staked and mined by users. <br/>
                                 After the halving event, the block reward will be halved and the remaining half of the reward is burned. This makes Bitcoin Halving Token scarcer with each halving, yet maintains a constant mining ratio.</p>
                            <p>Bitcoin Halving Token presents investors with a novel opportunity – the chance to own Bitcoin Halving Token at an exceptionally attractive price compared to Bitcoin.
                                <br/>With this vision, we aim to expand the project's reach in the cryptocurrency world, ensuring maximum profits for investors, especially those participating in the initial campaign.
                            </p>
                            <p></p>
                        </div>
                    </div>
                    <div className='w-operation'>
                        <h2>Operation BTC Halving Token</h2>
                        <img src={operation}/>
                        <img className='bg-d' src={bgabout}/>
                    </div>
                </div>
            </div>
            <div className='tokenomics'>
                <div className='w-tokenomic'>
                    <div className='s1-tokenomic'>
                        <img src={tokenomics}/>
                    </div>
                    <div className='s2-tokenomic'>
                        <h2 className=''>Tokenomics</h2>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>The total supply of BTC Halving Token is 210,000,000 tokens</p>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>40% of the tokens will be offered to the market to support the project's initiation and introduce it to the cryptocurrency community.</p>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>15% of the tokens will serve as rewards for early investors participating in staking.</p>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>15% of the tokens will be burned to mitigate token leakage from the market</p>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>10% of the tokens will be used for listing BTC Halving Token on centralized exchanges (CEX)</p>
                        <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>20% will be allocated for the project's marketing efforts and the launch of BTC Halving Exchange's CEX platform.</p>
                        
                        {/* <div className=''>
                            <div className=''>

                            </div>
                            <div className=''>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='roadmap'>
                <div className='w-roadmap'>
                    <h2>ROADMAP</h2>
                    <div className='w-s-roadmap'>
                        <div className='w-s-s-roadmap'>
                            <div className='line-through'></div>
                            <div className='s-s-roadmap'>
                                <h4>1</h4>
                                <div className='cnt-s-roadmap'>
                                    <h3>Q4 2023</h3>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Launching nodes on the ERC-20 platform.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Initiating the presale of BTC Halving Token.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Engaging the community through media channels and airdrops.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Implementing project marketing targeting the first 10,000 holders.</p>
                                </div>
                            </div>
                            <div className='s-s-roadmap'>
                                <h4>2</h4>
                                <div className='cnt-s-roadmap'>
                                    <h3>Q1 2024</h3>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Implementing a decentralized autonomous organization (DAO) model.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Buying back and burning to increase token scarcity.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Listing on major centralized exchanges (CEX).</p>
                                </div>
                            </div>
                            <div className='s-s-roadmap'>
                                <h4>3</h4>
                                <div className='cnt-s-roadmap'>
                                    <h3>Q2 2024</h3>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Achieving a trading volume of $100 million.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Supporting the development of the BTC Halving Token's independent network.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Developers launching a CEX trading platform, aiming for a 10% market capitalization share.</p>
                                </div>
                            </div>
                            <div className='s-s-roadmap'>
                                <h4>4</h4>
                                <div className='cnt-s-roadmap'>
                                    <h3>Q3 2024</h3>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Reaching 50,000 holders for the project.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Launching products in the fields of GameFi and NFT.</p>
                                    <p><span><img style={{width: '10px', marginRight: '10px'}} src={ellipse}/></span>Launching a marketplace on the BTC Halving Token chain (transaction fees on the platform will be distributed as dividends to holders).</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='faq'>
                <div className='w-faq'>
                    <h2>FAQ</h2>
                    <div className='i-w-faq'>
                        <div className='s-i-faq' onClick={question1}>
                            <div className='title-faq'>
                                <h4>What is BTC Halving Token</h4>
                                <img src={angledown}/>
                            </div>
                            <p className='cnt-faq q1'>After each Bitcoin Halving event, the block reward immediately halves. In contrast to Bitcoin, Bitcoin Halving Token is staked and mined by users.
                            After the halving event, the block reward will be halved and the remaining half of the reward is burned. This makes Bitcoin Halving Token scarcer with each halving, yet maintains a constant mining ratio.
                            <br/>Bitcoin Halving Token presents investors with a novel opportunity – the chance to own Bitcoin Halving Token at an exceptionally attractive price compared to Bitcoin.
                            With this vision, we aim to expand the project's reach in the cryptocurrency world, ensuring maximum profits for investors, especially those participating in the initial campaign.
                            </p>
                        </div>
                        <div className='s-i-faq' onClick={question2}>
                            <div className='title-faq'>
                                <h4>What do you mean by decentralized?</h4>
                                <img src={angledown}/>
                            </div>
                            <p className='cnt-faq q2'>It means you don't have to trust any central organization or person the way you would a bank. The developers nor team can seize your BTC Halving Token, but they also can't return them if you send them to the wrong address.
                            </p>
                        </div>
                        <div className='s-i-faq' onClick={question3}>
                            <div className='title-faq'>
                                <h4>How can I help?</h4>
                                <img src={angledown}/>
                            </div>
                                <p className='cnt-faq q3'>Tell your friends & family, about BTC Halving Token. Social media is a wonderful place.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}