import './Staking.css'
import arrow from '../assets/images/ani-arrow.svg'
import {sConnect, loginWithMetaMask, ETH_ID, check_chainId, BSC_ID, web3, account} from './Home'
import $ from "jquery"
import rewards_i from '../assets/images/rewards.jpg'
import ABI_TOKENH_BSC from './json/tokenH_bsc.json';
import ABI_TOKENH_ETH from './json/tokenH_eth.json';
import ABI_STAKE_BSC from './json/stake_bsc.json';
const address_tokenH_bsc = "0x1931fac6e0775Ed59d2FBF203Ff45f4692d86656";
const address_tokenH_eth = "0x1931fac6e0775Ed59d2FBF203Ff45f4692d86656";

const address_stake_bsc = "0x98cED216badeF835fBd64A56d47e7cF85f3A174E"
var contract_tokenH_bsc = null;
var contract_tokenH_eth = null;
var contract_stake_bsc = null;

export default function Staking(){
    let height = window.innerHeight;
    loginWithMetaMask();
    setTimeout(() => {
        $('.staking_btn').on('click', async ()=>{
            if(sConnect == false){
                await loginWithMetaMask();
            }
            else{
                await stakingFunc();
            }
        })
    }, 100);
    async function contract_bsc(){
        contract_tokenH_bsc = new web3.eth.Contract(ABI_TOKENH_BSC, address_tokenH_bsc);
        contract_stake_bsc = new web3.eth.Contract(ABI_STAKE_BSC, address_stake_bsc);
        $('.balance_token').text(parseInt(web3.utils.fromWei(await contract_tokenH_bsc.methods.balanceOf(account).call(), 'ether')))
        // await contract_stake_bsc.methods.owner().call().then(console.log)balance_staked
        $('.balance_staked').text(parseInt(web3.utils.fromWei(await contract_stake_bsc.methods.stakeToken(account).call(), 'ether')))
        $('.total_staked').text(parseInt(web3.utils.fromWei(await contract_stake_bsc.methods.Totaluser().call(), 'ether')))

    }
    async function contract_eth(){
        contract_tokenH_eth = new web3.eth.Contract(ABI_TOKENH_ETH, address_tokenH_eth);
        $('.balance_token').text(parseInt(web3.utils.fromWei(await contract_tokenH_eth.methods.balanceOf(account).call(), 'ether')))
    }
    function contract_null(){
        contract_tokenH_bsc = null;
        contract_tokenH_eth = null;
        contract_stake_bsc = null;
        $('.balance_token').text(0);
        $('.balance_staked').text(0);
        $('.rewards_token').text(0);
        $('.total_staked').text(0);
    }

    setInterval(async () => {
        if(sConnect == true){
            if( await check_chainId() == BSC_ID && contract_tokenH_bsc == null){
                await contract_bsc();
            }
            else if (await check_chainId() == ETH_ID && contract_tokenH_eth == null){
                await contract_eth();
            }
            else if(await check_chainId() != BSC_ID && await check_chainId() != ETH_ID){
                contract_null();
            }    
        }
        else{
            contract_null();
        }
    }, 1000);
    setInterval(async () => {
        if(sConnect == true){
            if( await check_chainId() == BSC_ID && contract_tokenH_bsc != null){
                $('.rewards_token').text(parseFloat(web3.utils.fromWei(await contract_stake_bsc.methods.showStaking(account).call(), 'ether')).toFixed(2));
            }
        }
    }, 1000);
    async function stakingFunc(){
        if( await check_chainId() == BSC_ID){
            const amount = parseFloat($('.amountS').val())*(10**18);
            if(amount > 0){
                await contract_tokenH_bsc.methods.allowance(account,address_stake_bsc).call().
                then(async (allow_token) =>{
                    if(allow_token < amount){
                        await contract_tokenH_bsc.methods.increaseAllowance(address_stake_bsc, await contract_tokenH_bsc.methods.balanceOf(account).call())
                        .send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                        .then(async () =>{
                            await contract_stake_bsc.methods.staking(amount)
                            .send({from: account, maxFeePerGas: 3*10**9, gas: 300000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                $('.staking_btn').text("Transaction Successfully!")
                                setTimeout(() => {
                                     $('.staking_btn').text("STAKE!")
                                }, 5000);
                                contract_bsc();
                            })
                            .catch((error) =>{
                                $('.staking_btn').text("Transaction Failed!")
                                setTimeout(() => {
                                     $('.staking_btn').text("STAKE!")
                                }, 5000); 
                            })
                        })
                        .catch((error) =>{
                            $('.staking_btn').text("Transaction Failed!")
                            setTimeout(() => {
                                 $('.staking_btn').text("STAKE!")
                            }, 5000); 
                        })
                    }
                    else{
                        await contract_stake_bsc.methods.staking(amount)
                        .send({from: account, maxFeePerGas: 3*10**9, gas: 300000, maxPriorityFeePerGas: 3*10**9})
                        .then(async ()=>{
                            $('.staking_btn').text("Transaction Successfully!")
                            setTimeout(() => {
                                 $('.staking_btn').text("STAKE!")
                            }, 5000);
                            contract_bsc();
                        })
                        .catch((error) =>{
                            $('.staking_btn').text("Transaction Failed!")
                            setTimeout(() => {
                                 $('.staking_btn').text("STAKE!")
                            }, 5000); 
                        })
                    }
                })

            }
            else{
               $('.staking_btn').text("AMOUNT!")
               setTimeout(() => {
                    $('.staking_btn').text("STAKE!")
               }, 2000);
            }
        }
        //else if (await check_chainId() == ETH_ID){
        //    const amount = parseFloat($('.amountS').val())*(10**18);
        //    console.log("c")
        //    if(amount == 0 || amount == null){
        //        $('.staking_btn').text("Amount!")
        //    }
        //}
        //else{
        //    console.log("c")
        //}
    }
    return(
        <div className='staking' style={{minHeight: height}}>
            <div className='w-staking'>
                <div className='s-title'>
                    <div className='i-s-title'>
                        <h2>WELCOME TO <span>BTC HALVING TOKEN STAKING</span></h2>
                        <p>BTCMTX token rewards will be distributed to users at a rate of 158.5 BTCMTX per ETH block. Rewards will be payable over 2 years and are determined by your share of the staking pool and annual returns percentage.</p>
                    </div>
                    <div></div>
                </div>
                <div className='w-i-s'>
                    <div className='f-i-s cm-s'>
                        <div className='o-s'>
                            <h4>YOUR BALANCE</h4>
                            <p><span className='balance_token'>0</span> BTCHALVING</p>
                        </div>
                        <div className='o-s'>
                            <h4>STAKED BALANCE</h4>
                            <p><span className='balance_staked'>0</span> BTCHALVING</p>
                        </div>
                        <div className='o-s'>
                            <h4>AMOUNT</h4>
                            <input className='amountS' placeholder='0'></input>
                        </div>
                        
                        <button className='staking_btn'>STAKE</button>
                    </div>
                    <div className='s-i-s cm-s'>
                        <h4>ESTIMATED REWARDS</h4>
                        <h3>250%</h3>
                        <div className='s-rw'>
                            <p><span><img src={arrow}/></span>Rewards rate is dynamic</p>
                            <p><span><img src={arrow}/></span>Monthly = Rewards % / 12</p>
                            <p><span><img src={arrow}/></span>Daily = Rewards % / 365</p>
                        </div>
                    </div>
                    <div className='f-i-s cm-s'>
                        <div className='o-s'>
                            <h4>CURRENT REWARDS</h4>
                            <p><span>50</span> Per Simulated Block</p>
                        </div>
                        <div className='o-s'>
                            <h4>TOTAL STAKED</h4>
                            <p><span className='total_staked'>0</span> BTCHALVING</p>
                        </div>
                    </div>
                    <div className='t-i-s cm-s'>
                        <div className='o-s'>
                            <h4>TOTAL REWARDS</h4>
                            <p><span className='rewards_token'>0</span> BTCHALVING</p>
                        </div>
                        <button>CLAIM</button>
                    </div>
                </div>
                <div className='rewards_i'>
                    <img src={rewards_i}/>
                </div>
            </div>
            
        </div>
    )
}